<template>
    <div class="master-wrap">
        <div class="master-header">
            <div>
                <Breadcrumbs
                    :items="items"
                />
                <h1>{{ $t('masterTitle') }}</h1>
                <h2 class="sec-title">{{ $t('masterCalendar') }}</h2>
                <h3 v-html="$t('howToApplyMaster')"></h3>
            </div>
            <!--<apply-button class="display-apply_btn" />-->
        </div>
        <div class="content">
            <div class="part-one">
                <h3>I – {{ $t('masterTitle1') }}</h3>
                <p>{{ $t('masterText1') }}</p>
            </div>
            <div class="mobile-table_wrapper">
                <div class="part-two">
                    <h3>II – {{ $t('masterTitle2') }}</h3>
                    <p v-html="$t('nationalityText')"></p>
                    <p v-html="$t('requiredLevel')"></p>
                    <p v-html="$t('trainingProgram')"></p>
                    <p v-html="$t('multipleScholarships')"></p>
                    <p v-html="$t('languageLevel')"></p>
                    <p v-html="$t('supportingDocuments')"></p>
                    <p>{{ $t('masterText2') }}</p>
                </div>
                <div class="part-three">
                    <h3>III – {{ $t('masterTitle3') }}</h3>
                    <div class="part-three-content">
                        <div class="numbering-content">
                            <p>1</p>
                            <p>{{ $t('masterText11') }}</p>
                        </div>
                        <div class="with-line-content">

                          <div class="with-line">
                            <p style="font-weight: bold;" v-html="$t('howToApplyMaster')"></p>
                          </div>
                            <div v-if="$i18n.locale === 'fr'" class="numbering-content">
                                <p>2</p>
                                <p>Calendrier</p>
                            </div>
                          <div class="with-line" v-if="$t('masterText13')">
                            <p v-html="$t('masterText13')"></p>
                          </div>
                        </div>
                        <div v-if="$t('masterList1')" class="numbering-content">
                            <p></p>
                            <p>{{ $t('masterList1') }}</p>
                        </div>
                        <div v-if="$t('masterList2')" class="numbering-content">
                          <p></p>
                          <p>{{ $t('masterList2') }}</p>
                        </div>
                        <div v-if="$t('masterList3')" class="numbering-content">
                          <p></p>
                          <p>{{ $t('masterList3') }}</p>
                        </div>
                        <div class="numbering-content" :class="$i18n.locale === 'fr'">
                            <p>3</p>
                            <p v-html="$t('calendar')"></p>
                        </div>
                        <div class="with-line-content">
                            <div class="with-line">
                                <p></p>
                                <p v-html="$t('lineText1')"></p>
                            </div>
                            <div class="with-line">
                                <p></p>
                                <p>{{ $t('lineText2') }}</p>
                            </div>
                            <div class="with-line">
                              <p></p>
                              <p>{{ $t('lineText3') }}</p>
                            </div>
                          <div class="with-line">
                            <p></p>
                            <p>{{ $t('lineText4') }}</p>
                          </div>
                        </div>
                        <div class="numbering-content" :class="$i18n.locale === 'fr'">
                            <p>4</p>
                            <p>{{ $t('masterText3') }}</p>
                        </div>
                        <div class="numbering-content">
                            <p></p>
                            <p>{{ $t('masterText31') }}</p>
                        </div>
                        <div class="numbering-content">
                            <p></p>
                            <p v-html="$t('masterText32')"></p>
                        </div>
                        <div class="numbering-content">
                            <p></p>
                            <p>{{ $t('masterText33') }}</p>
                        </div>
                        <div class="numbering-content">
                            <p></p>
                            <p>{{ $t('masterText34') }}</p>
                        </div>
                        <div class="numbering-content">
                            <p></p>
                            <p v-html="$t('masterText35')"></p>
                        </div>
                        <div class="numbering-content">
                            <p></p>
                            <p v-html="$t('masterText4')"></p>
                        </div>
                    </div>
                </div>
                <div class="part-four">
                    <h3>IV – {{ $t('masterTextTitle4') }}</h3>
                    <p>{{ $t('masterDescription41') }}</p>
                    <p>{{ $t('masterDescription42') }}</p>
                    <p>{{ $t('masterDescription43') }}</p>
                    <p>{{ $t('masterDescription44') }}</p>
                    <p>{{ $t('masterDescription45') }}</p>
                    <p>{{ $t('masterDescription46') }}</p>
                </div>
                <div class="part-five">
                    <h3>V – {{ $t('masterTextTitle5') }}</h3>
                    <div class="numbering-content">
                        <p>1</p>
                        <p>{{ $t('masterDescription51') }}</p>
                    </div>
                    <div class="with-line-content">
                        <div class="with-line">
                            <p>-</p>
                            <p>{{ $t('masterDescriptionLine1') }}</p>
                        </div>
                        <div class="with-line">
                            <p>-</p>
                            <p>{{ $t('masterDescriptionLine2') }}</p>
                        </div>
                        <div class="with-line">
                            <p>-</p>
                            <p>{{ $t('masterDescriptionLine3') }}</p>
                        </div>
                        <div class="with-line">
                            <p>-</p>
                            <p>{{ $t('masterDescriptionLine4') }}</p>
                        </div>
<!--                        <div class="with-background-content"> {{ $t('nb') }} </div>-->
                        <div class="with-line">
                            <p>-</p>
                            <p>{{ $t('masterDescriptionLine5') }}</p>
                        </div>
                        <div class="with-line">
                            <p>-</p>
                            <p>{{ $t('masterDescriptionLine6') }}</p>
                        </div>
                    </div>
                    <div class="numbering-content">
                        <p>2</p>
                        <p v-html="$t('masterDescription52')"></p>
                    </div>
                    <div class="numbering-content">
                        <p>3</p>
                        <p v-html="$t('masterDescription53')"></p>
                    </div>
                </div>
                <div class="part-six">
                    <h3>VI – {{ $t('masterTextTitle6') }}</h3>
                    <p v-html="$t('masterDescription61')"></p>
                    <p v-html="$t('masterDescription62')"></p>
                    <p>{{ $t('masterDescription63') }}</p>
                    <p>{{ $t('masterDescription64') }}</p>
                </div>
                <div class="part-documents">
                    <h3>{{ $t('masterDocumentsTitle') }}</h3>
                    <p v-html="$t('masterDocumentsSubTitle')" class="document-subtitle"></p>
                    <div class="numbering-content">
                        <p><b>1</b></p>
                        <p v-html="$t('masterDocumentsText1')"></p>
                    </div>
                    <div class="with-line">
                        <span>a</span>
                        <p v-html="$t('masterDocumentsText1a')"></p>
                    </div>
                    <div class="with-line">
                        <span>b</span>
                        <p v-html="$t('masterDocumentsText1b')"></p>
                    </div>
                    <div class="with-line">
                        <span>c</span>
                        <p v-html="$t('masterDocumentsText1c')"></p>
                    </div>
                    <div class="numbering-content">
                        <p><b>2</b></p>
                        <p v-html="$t('masterDocumentsText2')"></p>
                    </div>
                    <div class="with-line">
                        <span>a</span>
                        <p v-html="$t('masterDocumentsText2a')"></p>
                    </div>
                    <div class="with-line">
                        <span>b</span>
                        <p v-html="$t('masterDocumentsText2b')"></p>
                    </div>
                    <div class="with-line">
                        <span>c</span>
                        <p v-html="$t('masterDocumentsText2c')"></p>
                    </div>
                    <div class="with-line">
                        <span>d</span>
                        <p v-html="$t('masterDocumentsText2d')"></p>
                    </div>
                    <div class="with-line">
                        <span>e</span>
                        <p v-html="$t('masterDocumentsText2e')"></p>
                    </div>
                    <div class="numbering-content">
                        <p><b>3</b></p>
                        <p v-html="$t('masterDocumentsText3')"></p>
                    </div>
                    <div class="with-line">
                        <span>a</span>
                        <p v-html="$t('masterDocumentsText3a')"></p>
                    </div>
                    <div class="with-line">
                        <span>b</span>
                        <p v-html="$t('masterDocumentsText3b')"></p>
                    </div>
                    <!--<div v-if="$t('masterDocumentsText3c')" class="with-line">
                        <span>c</span>
                        <p v-html="$t('masterDocumentsText3c')"></p>
                    </div>-->
                    <div class="numbering-content">
                        <p><b>4</b></p>
                        <p v-html="$t('masterDocumentsText4')"></p>
                    </div>
                    <div class="with-line">
                        <span>a</span>
                        <p v-html="$t('masterDocumentsText4a')"></p>
                    </div>
                    <div class="numbering-content">
                        <p><b>5</b></p>
                        <p v-html="$t('masterDocumentsText5')"></p>
                    </div>
                    <div class="with-line">
                        <span>a</span>
                        <p v-html="$t('masterDocumentsText5a')"></p>
                    </div>
                    <div class="with-line">
                        <span>b</span>
                        <p v-html="$t('masterDocumentsText5b')"></p>
                    </div>
                    <!--<div v-if="$t('masterDocumentsText5c')" class="with-line">
                        <span>c</span>
                        <p v-html="$t('masterDocumentsText5c')"></p>
                    </div>-->
                  <div v-if="$t('masterDocumentsText5d')" class="with-line">
                        <span>c</span>
                        <p v-html="$t('masterDocumentsText5d')"></p>
                    </div>
                    <div class="numbering-content">
                        <p><b>6</b></p>
                        <p v-html="$t('masterDocumentsText6')"></p>
                    </div>
                    <div class="with-line">
                        <span>a</span>
                        <p v-html="$t('masterDocumentsText6a')"></p>
                    </div>
                    <div class="with-line">
                        <span>b</span>
                        <p v-html="$t('masterDocumentsText6b')"></p>
                    </div>
                    <div class="with-line">
                        <span>c</span>
                        <p v-html="$t('masterDocumentsText6c')"></p>
                    </div>
                    <div class="numbering-content">
                        <p><b>7</b></p>
                        <p v-html="$t('masterDocumentsText7')"></p>
                    </div>
                    <div class="with-line">
                        <span>a</span>
                        <p v-html="$t('masterDocumentsText7a')"></p>
                    </div>
                    <div class="numbering-content">
                        <p><b>8</b></p>
                        <p v-html="$t('passport')" style="font-weight: bold;"></p>
                    </div>
                    <p v-html="$t('masterDocumentsSubText')" class="document-subtitle"></p>
                    <div class="numbering-content">
                        <p><b>9</b></p>
                        <p v-html="$t('formMaster')" style="font-weight: bold;"></p>
                    </div>
                    <br>
                    <p v-html="$t('masterDocumentsForCandidate')" class="for-candidate-title"></p>
                    <div class="numbering-content">
                        <p>1</p>
                        <p v-html="$t('masterDocumentsForCandidate1')" class="for-candidate"></p>
                    </div>
                    <div class="numbering-content">
                        <p>2</p>
                        <p v-html="$t('masterDocumentsForCandidate2')" class="for-candidate"></p>
                    </div>
                    <div class="numbering-content">
                        <p>3</p>
                        <p v-html="$t('masterDocumentsForCandidate3')" class="for-candidate"></p>
                    </div>
                    <div class="numbering-content">
                        <p>4</p>
                        <p v-html="$t('masterDocumentsForCandidate4')" class="for-candidate"></p>
                    </div>
                </div>
                <div class="part-last">
                    <h3>{{ $t('helpfulInformation') }}</h3>
                    <p v-html="$t('info1')"></p>
                    <p>{{ $t('info2') }}</p>
                    <p v-html="$t('info3')"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
// import {mapActions, mapGetters} from "vuex";
// import ApplyButton from "@/components/apply-button";
import Breadcrumbs from '@/components/breadcrumbs';

export default {
    components: {
        //ApplyButton,
        Breadcrumbs,
    },
    data: () => ({
        items: [
            {
                text: 'home',
                href: '/'
            },
            {
                text: 'boursesEtudes',
                href: '#',
            },
            {
                text: 'master',
                href: '#',
            }
        ]
    }),
    computed: {
        ...mapGetters('applications', ['formattedScholarships']),
    },
    async mounted() {
//        if(!this.formattedScholarships) {
//            await this.getAllScholarships()
//        }
    },
    methods: {
//        ...mapActions('applications',['getAllScholarships']),
    }
}
</script>
<style lang="scss">
.sec-title {
  line-height: 30px;
  padding: 10px 0px;
}
.program-text {
    margin: 10px 0;
}

.underlineText {
  text-decoration: underline;
}
.red-numbering {
  p {
    color: red!important;
  }
}
</style>

<style lang="scss" scoped>
.mobile-table_wrapper {
    @media (max-width: 1024px) {
        margin-left: -373px;
        width: calc(100% + 373px);
        margin-top: 220px;
    }
    @media (max-width: 768px) {
        margin-left: 0;
        margin-top: 0;
        width: 100%;
    }
}
.display-apply_btn {
    position: fixed;
    right: 220px;
    @media (max-width: 1800px) {
        right: 180px;
    }
    @media (max-width: 1670px) {
        right: 120px;
    }
    @media (max-width: 1600px) {
        right: 80px;
    }
    @media (max-width: 1440px) {
        display: none;
    }
}
.master-wrap {
    .master-header {
        display: flex;
        justify-content: space-between;

        h1 {
            font-style: normal;
            font-weight: 900;
            font-size: 36px;
            line-height: 52px;
            max-width: 800px;
            @media (max-width: 1024px) {
                font-size: 22px;
                line-height: 34px;
            }
        }
    }
}

.content {

    .numbering-content {
        display: flex;
        margin-bottom: 4px;

        p {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            margin-bottom: 4px;
            margin-top: 15px;
        }

        p:nth-child(1) {
            width: 30px;
        }

        p:nth-child(2) {
            width: 100%;
            max-width: 676px;
            @media (max-width: 1024px) {
                max-width: 100%;
            }
        }
    }

    .with-line-content {
        width: 100%;
        max-width: 628px;
        margin-bottom: 15px;
        @media (max-width: 1024px) {
            max-width: 100%;
        }
    }

    .with-line {
        display: flex;
        margin: 11px 0 0 33px;

        p, span {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
        }

        p:nth-child(2) {
            margin-left: 12px;
            margin-bottom: 0;
            max-width: 660px;
        }
    }

    .part-one {
        h2 {
            margin-top: 40px;
            font-weight: bold;
            font-size: 22px;
            line-height: 34px;
        }

        h3 {
            margin-top: 20px;
            font-weight: bold;
            font-size: 22px;
            line-height: 34px;
            color: #000000;
        }

        p {
            margin-top: 15px;
            width: 100%;
            max-width: 705px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;

            @media (max-width: 1024px) {
                max-width: 100%;
            }
        }
    }

    .part-two {
        h3 {
            margin: 40px 0 15px 0;
            font-weight: bold;
            font-size: 22px;
            line-height: 34px;
            color: #000000;
        }

        p {
            margin-bottom: 15px;
            width: 100%;
            max-width: 700px;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            @media (max-width: 1024px) {
                max-width: 100%;
            }
        }
    }

    .part-three {
        h3 {
            margin-top: 25px;
            font-weight: bold;
            font-size: 22px;
            line-height: 34px;
            color: #000000;
        }

        .part-three-content {
            margin-top: 15px;
        }
    }

    .part-four, .part-six {
        h3 {
            margin: 36px 0 15px 0;
            font-weight: bold;
            font-size: 22px;
            line-height: 34px;
            color: #000000;
            max-width: 706px;
        }

        p {
            margin-bottom: 25px;
            width: 100%;
            max-width: 706px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;

            @media (max-width: 1024px) {
                max-width: 100%;
            }
        }
    }

    .part-documents {
        h3 {
            margin: 36px 0 15px 0;
            font-weight: bold;
            font-size: 22px;
            line-height: 34px;
            color: #000000;
            max-width: 706px;
        }

        p {
            width: 100%;
            max-width: 706px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;

            @media (max-width: 1024px) {
                max-width: 100%;
            }
        }
    }

    .document-subtitle {
        padding: 16px;
        background-color: #F5F8FD;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .part-five {
        h3 {
            margin-bottom: 15px;
            font-weight: bold;
            font-size: 22px;
            line-height: 34px;
            color: #000000;
        }

        .with-background-content {
            width: 100%;
            margin: 20px 0 20px 33px;
            max-width: 665px;
            background: #F5F8FD;
            padding: 16px 14px 17px 18px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            @media (max-width: 1024px) {
                max-width: 100%;
            }
        }
    }

    .part-last {
        h3 {
            margin: 36px 0 15px 0;
            font-weight: bold;
            font-size: 22px;
            line-height: 34px;
            color: #000000;
        }

        p {
            margin-bottom: 30px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
        }
    }

    .for-candidate-title {
        margin-bottom: 15px;
    }
    .for-candidate {
        margin-bottom: 4px!important;
        margin-top: 0!important;
    }
}
</style>